<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w200 mr10 mb20"
          placeholder="请输入新聞標題"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
          <el-button type="primary" @click="jumpToCreate">新增</el-button>
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            label="新聞標題"
            prop="title"
            width="300"
          ></el-table-column>
          <el-table-column label="新聞日期" width="150">
            <template slot-scope="scope">{{
              scope.row.news_time | formatTime
            }}</template>
          </el-table-column>
          <el-table-column label="顯示權重" prop="sort"></el-table-column>
          <el-table-column label="展示狀態">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.show"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ccc"
                @change="togglePublish(scope.row.show, scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="jumpToEdit(scope.$index)"
                >編輯</el-link
              >
              <el-popconfirm
                confirmButtonText="確定"
                cancelButtonText="取消"
                icon="el-icon-info"
                iconColor="red"
                title="確定刪除嗎？"
                @onConfirm="handleDelete(scope.$index)"
              >
                <el-link slot="reference" type="danger" class="ml10"
                  >删除</el-link
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { calTableHeight } from "@/common/tool"
export default {
  data() {
    return {
      loadList: false,
      showCreateDialog: false,
      curItem: {},
      curIndex: -1,
      type: "create",
      filters: {
        title: "",
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.news.getNews({ page, ...this.filters })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: "",
      }
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    jumpToCreate() {
      this.$router.push({
        name: "newsCreate",
        params: {
          type: "create",
        },
      })
    },
    jumpToEdit(index) {
      this.$router.push({
        name: "newsCreate",
        query: {
          page: this.tableData.current_page,
          id: this.tableData.data[index].id,
        },
        params: {
          type: "edit",
        },
      })
    },
    async handleDelete(index) {
      let item = this.tableData.data[index]
      if (item.show == 0) {
        this.$message.warning("顯示狀態數據不可刪除，請先隱藏")
      } else {
        let res = await this.api.news.deleteNews(item.id)
        if (res && res.code == 0) {
          this.$message({
            message: "刪除成功",
            type: "success",
          })
          this.tableData.data.splice(index, 1)
        }
      }
    },
    togglePublish(value, index) {
      if (value == 1) {
        this.unPublish(index)
      } else {
        this.publish(index)
      }
    },
    async unPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.news.switchNews(id, 1)
      if (res && res.code == 0) {
        console.log(res.data)
        // this.tableData.data[index].show = 1;
        this.$message({
          message: "隱藏成功",
          type: "success",
        })
      }
    },
    async publish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.news.switchNews(id, 0)
      if (res && res.code == 0) {
        // this.tableData.data[index].show = 0;
        this.$message({
          message: "顯示成功",
          type: "success",
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.c-note {
  line-height: 20px;
  overflow: hidden;
  height: 40px;
}
</style>
